import { ActionListButton } from '@UI/index';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import {
  selectAllCreatorsActiveInSchoolyear,
  selectCurrentSchoolHref,
  selectCurrentSchoolyear,
  selectPrincipalTeamsForSchoolyear,
} from '@store/userAndSchool/userAndSchoolSelectors';
import { useSelector, useDispatch } from 'react-redux';
import { setOrgContext } from '@store/userAndSchool/usersAndSchoolState';
import { useNavigate } from 'react-router-dom';

const LeerplanListItemActions = ({
  item,
  expired,
  onEditNonderived,
  onCreateSchoolCurricula,
  onCreateTeamCurricula,
  onCreatePersonalCurricula,
  onDistributeMultipleTeams,
  onDelete,
}) => {
  const dispatch = useDispatch();
  const schoolHref = useSelector(selectCurrentSchoolHref);
  const schoolYear = useSelector(selectCurrentSchoolyear);
  const orgs = useSelector(selectAllCreatorsActiveInSchoolyear);
  const principalTeams = useSelector(selectPrincipalTeamsForSchoolyear);
  const navigate = useNavigate();

  const distributeSchoolHandler = () => {
    if (schoolHref) {
      dispatch(setOrgContext(schoolHref));
    }

    const params = new URLSearchParams({
      custid: item.custid || '',
      schoolyear: schoolYear?.key ?? '',
    });

    const url = `/leerplan/${item.id}/distributie?${params.toString()}`;
    navigate(url);
  };

  const distributeSingleTeamHandler = (team) => {
    const curTeam = orgs.find((e) => e.href === team.$$meta.permalink);
    if (curTeam) dispatch(setOrgContext(curTeam.href));

    const params = new URLSearchParams({
      custid: item.custid || '',
      schoolyear: schoolYear?.key ?? '',
    });

    const url = `/leerplan/${item.id}/distributie?${params.toString()}`;
    navigate(url);
  };

  const distributionTeamsHandler = () => {
    if (principalTeams.length > 1) {
      onDistributeMultipleTeams(item);
    } else {
      distributeSingleTeamHandler(principalTeams[0]);
    }
  };

  return (
    <div className="actions">
      {item.canEditCodeAndTitle && (
        <ActionListButton
          icon={<EditIcon />}
          label="Bewerken"
          disabled={expired}
          onClick={() => onEditNonderived(item)}
          testId="edit-button"
        />
      )}
      {item.showSchool && (
        <ActionListButton
          icon={<EditIcon />}
          label="Leerplan op schoolniveau maken"
          disabled={expired}
          onClick={() => onCreateSchoolCurricula(item)}
          testId="create-curriculum-school-level-button"
        />
      )}
      {item.showTeam && (
        <ActionListButton
          icon={<EditIcon />}
          label="Leerplan groep maken"
          disabled={expired}
          onClick={() => onCreateTeamCurricula(item)}
          testId="create-group-lesson-plan-button"
        />
      )}
      {item.showPersonal && (
        <ActionListButton
          icon={<EditIcon />}
          label="Leerplan leraar maken"
          disabled={expired}
          onClick={() => onCreatePersonalCurricula(item)}
          testId="create-teacher-curriculum-button"
        />
      )}
      {item.showDistributeSchoolButton && (
        <ActionListButton
          key="schooldist"
          icon={<i className="share-icon glyphicon glyphicon-share"></i>}
          label="Verdelen op schoolniveau"
          disabled={expired}
          onClick={() => distributeSchoolHandler()}
          testId="distribute-at-school-level-button"
        />
      )}
      {item.showDistributeTeamButton && (
        <ActionListButton
          key="teamdist"
          icon={<i className="share-icon glyphicon glyphicon-share"></i>}
          label="Verdelen op teamniveau"
          disabled={expired}
          onClick={() => distributionTeamsHandler()}
          testId="distribute-at-team-level-button"
        />
      )}
      {item.canDelete && (
        <ActionListButton
          icon={<DeleteIcon />}
          label="Verwijderen"
          onClick={() => onDelete(item)}
          testId="delete-button"
          disabled={expired}
        />
      )}
    </div>
  );
};

export default LeerplanListItemActions;
