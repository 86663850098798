import { useEffect } from 'react';
import { getStudyProgrammeTypeNameForGrade } from '@utils/curriculumHelper';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCurriculumGrade,
  selectSelectedStudyPrograms,
} from '@store/curriculum/curriculumSelectors';
import { selectShouldStudyProgrammeAlertBeShown } from '@components/leerplan/alert/StudyProgrammesAlertSelectors';
import { setFilter } from '@store/distribute/distributeState';

import { selectIsDistributionMode } from '@store/distribute/distributeSelectors';
import { useNavigate } from 'react-router-dom';

const StudyProgrammesAlert = ({ stateParams, distributeSelectionModalRef }) => {
  const dispatch = useDispatch();
  const grade = useSelector(selectCurriculumGrade);
  const gradeName = getStudyProgrammeTypeNameForGrade(grade);
  const isDistributionMode = useSelector(selectIsDistributionMode);
  const shouldStudyProgrammeAlertBeShown = useSelector(selectShouldStudyProgrammeAlertBeShown);
  const studyProgrammeHrefs = useSelector(selectSelectedStudyPrograms);

  const navigate = useNavigate();

  const openDistributeModal = () => {
    const modalRef = distributeSelectionModalRef.current;
    modalRef?.open({
      onConfirm: (selectedPrograms) => {
        const url = new URL(window.location.href);
        const params = new URLSearchParams(url.search);

        if (selectedPrograms.length === 0) {
          navigate('/home/leerplan');
        } else {
          params.set('studid', selectedPrograms.join(','));
          navigate(`${url.pathname}?${params.toString()}`);
        }
      },
      onClose: () => {
        if (isDistributionMode && studyProgrammeHrefs.length === 0) {
          navigate('/home/leerplan');
        }
      },
    });
  };

  useEffect(() => {
    const modalRef = distributeSelectionModalRef.current;
    if (isDistributionMode && !stateParams.studids?.length) {
      openDistributeModal();
    }
    return () => {
      modalRef?.close();
      dispatch(setFilter({ property: 'text', value: '' }));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {shouldStudyProgrammeAlertBeShown && studyProgrammeHrefs.length > 0 && (
        <div className="studyProgrammes">
          ! Je maakt momenteel keuzes voor {studyProgrammeHrefs.length} {gradeName}{' '}
          <a onClick={openDistributeModal}>wijzigen</a>
        </div>
      )}
    </>
  );
};

export default StudyProgrammesAlert;
