import { HoveredPopover } from '@UI/index';

import { useSelector } from 'react-redux';

import './nonderivedMenuOptions.scss';
import { selectCurriculaReadOnly } from '@store/curriculum/curriculumSelectors';
import { selectIsDistributionMode } from '@store/distribute/distributeSelectors';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import { CUSTOMTYPES } from '@utils/curriculumHelper';

const NonderivedMenuOptions = ({
  goal,
  isFirstItem,
  children,
  onShowNonderivedModal,
  onCompleteDistributionOrDelete,
  onShowEditGoalModal,
  onExclude,
}) => {
  const isNonderived = useSelector((state) => state.curriculum.curriculumKey === 'nonderived');
  const isSelection = useSelector((state) => state.curriculum.selectionMode);
  const isDistributionMode = useSelector(selectIsDistributionMode);

  const readonly = useSelector(selectCurriculaReadOnly);
  const isInteractive = !readonly && !goal.locked && !isSelection;
  const isPartialDistribution = goal.partialDistribution;
  const isSection = goal.type === CUSTOMTYPES.section;
  const isCustomGoal = goal.type === CUSTOMTYPES.goal;

  const getSectionButtonIcon = () => {
    if (goal.excluded && !goal.partialInclusion) return <CheckIcon />;
    if (!isSection && !goal.excluded && !goal.partialInclusion) return <ClearIcon />;
    if (isSection) return <ClearIcon />;
    if (goal.partialInclusion) return <AddIcon />;
    return undefined;
  };

  const getSectionButtonContent = () => {
    if (goal.excluded && !goal.partialInclusion) return 'Opnemen';
    if (!isSection && !goal.excluded && !goal.partialInclusion) return 'Niet opnemen';
    if (isSection) return 'Verwijderen';
    if (goal.partialInclusion) return 'Opnemen in alle leerplannen';
    return undefined;
  };

  const onShowNonderivedModalHandler = (topClick) => {
    onShowNonderivedModal?.(isFirstItem, topClick ? null : goal);
  };

  const onCompleteDistributionOrDeleteHandler = () => {
    onCompleteDistributionOrDelete?.(goal);
  };

  const onEditHandler = () => {
    onShowEditGoalModal?.(goal);
  };

  const onExcludeHandler = () => {
    onExclude?.(goal);
  };

  if (
    !goal ||
    isSelection ||
    isDistributionMode ||
    (!isNonderived && goal.type === 'LLINKID_GOAL_SECTION')
  )
    return children;

  return (
    <div data-testid="nonderived-menu-options" className={`nonderived-menu-options`}>
      {isNonderived && isInteractive && isFirstItem && (
        <div className="menu-options top">
          <Button
            variant="contained"
            color="darkGreen"
            size="small"
            className="nonderived-option"
            startIcon={<AddIcon />}
            onClick={() => onShowNonderivedModalHandler(true)}
          >
            Invoegen
          </Button>
        </div>
      )}

      {(!goal.partialDescription || goal.partialDescription === '') && (
        <div className="wrapper">{children}</div>
      )}
      {/* TODO: move this logic unrelated to the nonderived options out */}
      {goal.partialDescription && goal.partialDescription !== '' && (
        <div className="wrapper">
          <HoveredPopover content={goal.partialDescription} positions={['bottom']}>
            {children}
          </HoveredPopover>
        </div>
      )}

      {isInteractive && (
        <div className="menu-options">
          {isNonderived && (
            <Button
              variant="contained"
              color="darkGreen"
              size="small"
              className="nonderived-option"
              startIcon={<AddIcon />}
              onClick={() => onShowNonderivedModalHandler(false)}
            >
              Invoegen
            </Button>
          )}

          {isPartialDistribution && goal.annotation.type === 'REFERENCE' && (
            <Button
              variant="contained"
              color="darkGreen"
              size="small"
              className="nonderived-option"
              startIcon={<i className="share-icon glyphicon glyphicon-share"></i>}
              onClick={onCompleteDistributionOrDeleteHandler}
            >
              Kopiëren in alle leerplannen
            </Button>
          )}
          {isPartialDistribution && goal.annotation.type === 'MOVE' && (
            <Button
              variant="contained"
              color="darkGreen"
              size="small"
              className="nonderived-option"
              startIcon={<ContentCutIcon />}
              onClick={onCompleteDistributionOrDeleteHandler}
            >
              Verplaatsen in alle leerplannen
            </Button>
          )}
          {isCustomGoal && (
            <Button
              variant="contained"
              color="darkGreen"
              size="small"
              className="nonderived-option"
              startIcon={<EditIcon />}
              onClick={onEditHandler}
            >
              Bewerken
            </Button>
          )}
          {(isCustomGoal ||
            (!goal.partialDistribution && !goal.subGoal && goal.foundationallyEditable)) && (
            <Button
              variant="contained"
              color="darkGreen"
              size="small"
              className="nonderived-option"
              startIcon={<ClearIcon />}
              onClick={onCompleteDistributionOrDeleteHandler}
            >
              Verwijderen
            </Button>
          )}

          {(isSection || !goal.mandatory) && (
            <Button
              variant="contained"
              color="darkGreen"
              size="small"
              className="nonderived-option"
              startIcon={getSectionButtonIcon()}
              onClick={onExcludeHandler}
            >
              {getSectionButtonContent()}
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default NonderivedMenuOptions;
