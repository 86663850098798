import iconPdf from '@assets/img/icon_pdf.svg';
import iconWord from '@assets/img/icon_word.svg';
import iconExcelGrey from '@assets/img/icon_excel_grey.svg';
import iconPowerpointGrey from '@assets/img/icon_powerpoint_grey.svg';
import iconExternalLink from '@assets/img/icon_external_link.svg';
import settings from '@config/settings';

import './attachment.scss';

const getAttachmentName = (att) => {
  let name = att.description;

  if (!name && att.url) {
    name = att.url;
  }
  if (!name && att.href) {
    name = att.href.split('/').pop();
  }

  if (!name && att.name) {
    name = att.name;
  }

  return name;
};

const getAttachmentUrl = (att) => {
  console.log({ att });
  let url = att?.href;
  if (!att?.href?.includes('http')) {
    url = settings.apisAndUrls.api + att.href;
  }

  return url;
};

const getAttachmentIcon = (att) => {
  const type = att.contentType || att.type;

  const ICONS = {
    'application/pdf': iconPdf,
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': iconWord,
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': iconExcelGrey,
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': iconPowerpointGrey,
    'image/svg+xml': 'glyphicon glyphicon-picture',
    'image/png': 'glyphicon glyphicon-picture',
    'image/jpeg': 'glyphicon glyphicon-picture',
  };

  return ICONS[type] || iconExternalLink;
};

const Attachment = ({ item }) => {
  const url = getAttachmentUrl(item);
  const name = getAttachmentName(item);
  if (item.contentType === '') {
    if (name.includes('xlsx') !== -1)
      item.contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    else if (name.includes('pptx') !== -1)
      item.contentType =
        'application/vnd.openxmlformats-officedocument.presentationml.presentation';
  }
  const icon = getAttachmentIcon(item, name);
  const isImage = !icon.includes('glyphicon');

  return (
    <div className="attachment">
      <a href={url} target="_blank" rel="noreferrer" onClick={(ev) => ev.stopPropagation()}>
        <div className="icon">
          {isImage && <img src={icon} />}
          {!isImage && <i className={icon}></i>}
        </div>
        <div className="name">{name}</div>
      </a>
    </div>
  );
};

export default Attachment;
