import './distributeCard.scss';

const DistributeCard = ({ title, description, onClick = () => {} }) => {
  return (
    <div className="react-distribute-card" onClick={onClick}>
      <div className="info">
        <h4>{title}</h4>
        <p>{description}</p>
      </div>
      <div className="right-button">
        <i className="glyphicon glyphicon-menu-right"></i>
      </div>
    </div>
  );
};

export default DistributeCard;
