import GoalSelectorModal from '@components/leerplan/modals/goalSelectorModal/GoalSelectorModal';
import { Modal } from '@kathondvla/react-shared-components/src/components';
import AddBoxIcon from '@mui/icons-material/AddBox';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import { Button, CircularProgress } from '@mui/material';
import { addWeekAt, moveStartDateWeeks, removeWeekAt } from '@store/calendar/calendarHelper';
import { selectCalendarEditModalViewModel } from '@store/calendar/calendarSelectors';
import {
  deleteActivity,
  editActivityModalClose,
  saveActivity,
} from '@store/calendar/calendarState';
import { curriculumActions, setBaseGoalOccurence } from '@store/curriculum/curriculumState';
import ActivityChildEditor from '@UI/activityChildEditor/ActivityChildEditor';
import ActivityGoalSelector from '@UI/activityGoalSelector/ActivityGoalSelector';
import ConfirmModal from '@UI/confirmModal/ConfirmModal';
import ModalErrorBoundary from '@UI/errorBoundaries/ModalErrorBoundary';
import { FormSelect, FormTextField, ToggableButton } from '@UI/index';
import { requiredValidation } from '@utils/validations';
import { isEqual } from 'lodash-es';
import { array, bool, func, object } from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import iconAddWhite from '@assets/img/add_white.svg';
import iconEditWhite from '@assets/img/edit-white.svg';

import './modals.scss';

const EDIT_PROPS = {
  title: 'Bewerken',
  headerIcon: iconEditWhite,
  confirmLabel: 'Activiteit opslaan',
};

const CREATE_PROPS = {
  title: 'Toevoegen',
  headerIcon: iconAddWhite,
  confirmLabel: 'Activiteit aanmaken',
};

const EditActivityModal = () => {
  const { isCreate } = useSelector((state) => state.calendar.editActivityModal.data);
  const isSaving = useSelector((state) => state.calendar.editActivityModal.status === 'SAVING');
  const { confirmLabel, title, headerIcon } = isCreate ? CREATE_PROPS : EDIT_PROPS;
  const {
    scrollTo,
    classes: origClasses,
    startDates,
    curriculaGoals,
    invalidGoals,
    curricula,
    model: vm,
    baseOccurencesPerGoal,
    arePlanGoalsLoaded, // it's possible to open the modal before all goals are loaded. In that case, we need to wait for them to be loaded before we can render the goalSelector.
  } = useSelector((state) => selectCalendarEditModalViewModel(state));
  const [model, setModel] = useState(vm);
  const [occurencesPerGoal, setOccurencesPerGoal] = useState(baseOccurencesPerGoal);
  const [classes, setClasses] = useState(origClasses);
  const [childGoalOptions, setChildGoalOptions] = useState(curriculaGoals);
  const noClasses = origClasses?.filter((c) => c.selected).length === 0;
  const deleteAllConfirmModal = useRef();
  const goalSelectorModalRef = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    if (arePlanGoalsLoaded) {
      // update the model and the childGoalOptions when ALL goals are loaded
      setChildGoalOptions(curriculaGoals);
      setModel(vm);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [arePlanGoalsLoaded]);

  useEffect(() => {
    const { body } = document;
    body.classList.add('overflow-hidden');
    return () => {
      body.classList.remove('overflow-hidden');
    };
  }, []);

  const toggleClassHandler = (cls) => {
    if (!isCreate) return;
    const index = classes.indexOf(cls);

    setClasses((prev) => {
      const next = [...prev];
      next.splice(index, 1, { ...cls, selected: !cls.selected });
      return next;
    });
  };

  const deleteAll = () => {
    deleteAllConfirmModal?.current.open({
      title: 'Activiteit verwijderen?',
      message: 'Ben je zeker dat je de hele activiteit wil verwijderen?',
      confirmLabel: 'Verwijder',
      onConfirm: () => {
        dispatch(deleteActivity({ parentHrefs: vm.parentHrefs }));
        dispatch(editActivityModalClose());
      },
      onClose: () => {},
    });
  };

  const updateParentPeriod = (weeks) => {
    const parentStartDate = weeks[0].period.startDate;
    const parentEndDate = weeks[weeks.length - 1].period.endDate;

    setModel((prev) => ({
      ...prev,
      period: { startDate: parentStartDate, endDate: parentEndDate },
    }));
  };

  const changeStartDate = (value) => {
    const updatedWeeks = moveStartDateWeeks(value, model.weeks);

    updateParentPeriod(updatedWeeks);
    setModel((prev) => ({
      ...prev,
      weeks: updatedWeeks,
    }));
  };

  const changeParentGoals = (goals) => {
    const goalsKey = goals.map((g) => g.key);
    const filteredGoals = curriculaGoals.filter((g) => !goalsKey.includes(g.key));

    setModel((prev) => ({
      ...prev,
      goals,
    }));

    setChildGoalOptions(filteredGoals);
  };

  const addWeekBefore = (act) => {
    let position = 0;

    if (!act) position = 0;
    else position = model.weeks.findIndex((w) => w.key === act.key);

    const updatedWeeks = addWeekAt(position, model.weeks);
    updateParentPeriod(updatedWeeks);
    setModel((prev) => ({
      ...prev,
      weeks: updatedWeeks,
    }));
  };

  const addWeekAfter = (act) => {
    let position = 0;

    if (!act) position = model.weeks.length;
    else position = model.weeks.findIndex((w) => w.key === act.key) + 1;

    const updatedWeeks = addWeekAt(position, model.weeks);
    updateParentPeriod(updatedWeeks);
    setModel((prev) => ({
      ...prev,
      weeks: updatedWeeks,
    }));
  };

  const onActivityRemove = (act) => {
    // TODO: confirm modal if attachments
    const position = model.weeks.findIndex((w) => w.key === act.key);

    const updatedWeeks = removeWeekAt(position, model.weeks);
    if (!updatedWeeks.length) {
      return; // you can't remove the last remaining week.
    }
    updateParentPeriod(updatedWeeks);
    setModel((prev) => ({
      ...prev,
      weeks: updatedWeeks,
    }));
  };

  const onActivityChange = (act) => {
    const updatedWeeks = model.weeks.map((e) => {
      if (e.key === act.key) return act;
      return e;
    });
    setModel((prev) => ({ ...prev, weeks: updatedWeeks }));
  };

  const onConfirmHandler = (formValues) => {
    dispatch(
      saveActivity({
        ...model,
        classHrefs: classes.filter((e) => e.selected).map((e) => e.$$meta.permalink),
        title: formValues.title,
        isCreate,
      })
    );
  };

  const onCloseHandler = () => {
    dispatch(editActivityModalClose());
  };

  const selectLeerplan = (item, cur, type = 'parent') => {
    const selectedGoalHrefs = new Set(item.goals.map((z) => z.href));
    const countMap = Object.fromEntries(
      curriculaGoals.map((g) => [
        g.href,
        selectedGoalHrefs.has(g.href)
          ? occurencesPerGoal.get(g.href) - 1
          : occurencesPerGoal.get(g.href), // we make it a base-count for this specific parent or child. if nothing were selected, these are the counts.
      ])
    );

    dispatch(setBaseGoalOccurence({ goalOccurence: countMap }));
    // this is a bit of a dirty way of getting the baseGoalOccurence for the goalCount selector.
    // the problem is that this modal doesn't live in the state, so we have no way to repeatedly calculate the real baseGoalOccurence.
    // one way to fix this is to move the whole modal into the state, but doing this to only facilitate the goal count number in the curriculum is overkill
    // saving the basecount in the state is therefor an acceptable workaround, albeit a bit dirty.

    dispatch(curriculumActions.setSelectedItems(item.goals.map((z) => ({ href: z.href }))));

    return goalSelectorModalRef.current.open({
      params: cur,
      onClose: () => {
        dispatch(setBaseGoalOccurence({ goalOccurence: null }));
      },
      /**
       * @param {string[]} selection - array of goal hrefs
       */
      onConfirm: (selection) => {
        dispatch(setBaseGoalOccurence({ goalOccurence: null }));

        const oldGoalsHash = item.goals?.map((g) => g.href).join();
        const newGoalsHash = selection?.join('');

        if (oldGoalsHash !== newGoalsHash) {
          const curriculaGoalsAndInvalidGoals = [...curriculaGoals, ...invalidGoals];
          const selectedGoals = selection?.map((g) =>
            curriculaGoalsAndInvalidGoals.find((cg) => cg.href === g)
          );
          if (type === 'parent') changeParentGoals(selectedGoals);
          else if (type === 'child') onActivityChange({ ...item, goals: selectedGoals });
        }
      },
    });
  };

  const { control, handleSubmit } = useForm();

  useEffect(() => {
    if (!arePlanGoalsLoaded) return;

    const modifiedOccurences = new Map([...baseOccurencesPerGoal]);
    const allGoals = [...model.goals, ...model.weeks.map((z) => z.goals).flat()];
    allGoals.forEach((goal) => {
      modifiedOccurences.set(goal.href, modifiedOccurences.get(goal.href) + 1);
    });
    if (!isEqual(modifiedOccurences, occurencesPerGoal)) {
      // this is to avoid rerenders
      setOccurencesPerGoal(modifiedOccurences);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model, baseOccurencesPerGoal, arePlanGoalsLoaded]);

  return (
    <>
      <Modal.Header title={title} icon={headerIcon} onClose={onCloseHandler} />

      <Modal.Body>
        <div className={`edit-activity-modal ${isCreate ? 'create' : 'modify'}`}>
          <section className="parent-info">
            <div className="range-options">
              <div className="periode">
                <span className="label">Periode</span>
                <div className="period-selector">
                  <FormSelect
                    name="period"
                    value={model.period?.startDate}
                    options={startDates}
                    control={control}
                    onChange={changeStartDate}
                  />
                </div>
              </div>
              <div className="duration">
                <span className="label">Duur </span>
                <span className="form-text">{model.weeks.length} weken</span>
              </div>
              <div className="classes">
                <div className="classes-buttons">
                  <span className="label">Klassen </span>
                  {classes.map((cls) => (
                    <ToggableButton
                      key={cls.href}
                      isActive={cls.selected}
                      activeColor={cls.color.background}
                      onClick={() => toggleClassHandler(cls)}
                    >
                      {cls.$$displayName}
                    </ToggableButton>
                  ))}
                </div>
                {noClasses && <span className="error-msg">Selecteer een klas</span>}
              </div>
            </div>

            <div className="goals">
              <span className="label">Leerplandoelen</span>
              {arePlanGoalsLoaded && (
                <ActivityGoalSelector
                  goals={model.goals}
                  occurencesPerGoal={occurencesPerGoal}
                  curricula={curricula}
                  options={childGoalOptions}
                  onChange={changeParentGoals}
                  onLeerplanSelection={(cur) => selectLeerplan(model, cur, 'parent')}
                />
              )}
            </div>

            <div className="title">
              <span className="label">Thema*</span>
              <FormTextField
                name="title"
                control={control}
                value={model.title}
                validations={{
                  required: (value) => requiredValidation(value),
                }}
              />
            </div>
          </section>

          <section className="children-info">
            <Button
              variant="outlined"
              color="grey"
              startIcon={<AddBoxIcon />}
              className="add-button"
              onClick={() => addWeekBefore()}
            >
              Week invoegen
            </Button>
            <div className="children">
              {model?.weeks.map((child, index) => (
                <div key={child.key} className="activity-wrapper">
                  <Button
                    variant="outlined"
                    color="grey"
                    startIcon={<AddBoxIcon />}
                    className="add-button"
                    onClick={() => addWeekBefore(child)}
                  >
                    Week tussenvoegen
                  </Button>
                  <ActivityChildEditor
                    key={child.key}
                    activity={child}
                    curricula={curricula}
                    goalOptions={childGoalOptions}
                    occurencesPerGoal={occurencesPerGoal}
                    renderTo={child.key === scrollTo && index !== 0}
                    onLeerplanSelection={selectLeerplan}
                    onChange={onActivityChange}
                    onRemove={onActivityRemove}
                  />
                  <Button
                    variant="outlined"
                    color="grey"
                    startIcon={<AddBoxIcon />}
                    className="add-button after"
                    onClick={() => addWeekAfter(child)}
                  >
                    Week tussenvoegen
                  </Button>
                </div>
              ))}
            </div>
            <Button
              variant="outlined"
              color="grey"
              startIcon={<AddBoxIcon />}
              className="add-button after last"
              onClick={() => addWeekAfter()}
            >
              Week hierna
            </Button>
          </section>
        </div>
      </Modal.Body>

      <Modal.Footer>
        {!isCreate && (
          <div className="delete-action">
            <Button variant="outlined" color="error" startIcon={<DeleteIcon />} onClick={deleteAll}>
              Activiteit verwijderen
            </Button>
          </div>
        )}
        <Button variant="outlined" onClick={onCloseHandler}>
          Annuleren
        </Button>
        <Button
          variant="contained"
          onClick={handleSubmit((form) => {
            if (isSaving) return;
            onConfirmHandler(form);
          })}
          startIcon={
            // eslint-disable-next-line no-nested-ternary
            isSaving ? (
              <CircularProgress
                size={20}
                sx={{ m: 'auto', display: 'block', color: 'primary.contrastText' }}
              />
            ) : isCreate ? (
              <AddBoxIcon />
            ) : (
              <SaveIcon />
            )
          }
        >
          <span>{confirmLabel}</span>
        </Button>
      </Modal.Footer>

      <Modal
        ref={deleteAllConfirmModal}
        component={<ConfirmModal />}
        size="sm"
        errorBoundaryComponent={ModalErrorBoundary}
      />

      <Modal
        ref={goalSelectorModalRef}
        component={<GoalSelectorModal />}
        errorBoundaryComponent={ModalErrorBoundary}
        size="xl"
      />
    </>
  );
};

EditActivityModal.propTypes = {
  activity: object,
  isCreate: bool,
  week: object,
  classes: array,
  onClose: func,
  onConfirm: func,
};

export default EditActivityModal;
