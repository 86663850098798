import DistributeWindow from '@components/distribution/distributeWindow/DistributeWindow';
import { Compiler, Modal } from '@kathondvla/react-shared-components/src/components';
import {
  selectCurriculaReadOnly,
  selectGoalCount,
  selectIsGoalSelected,
  selectIsSelectionReadonlyMode,
} from '@store/curriculum/curriculumSelectors';
import { hasGoalDistributions } from '@store/distribute/distributeHelper';
import {
  selectDistributeVMForGoal,
  selectEmptyOrLowPrioGoals,
  selectIsCurriculaDistributable,
  selectIsDistributionMode,
} from '@store/distribute/distributeSelectors';
import {
  completeDistributionOrDeleteItem,
  distributeGoal,
  includeItem,
  moveItem,
} from '@store/llinkidApis/llinkidApiState';
import BasisLink from '@UI/basisLink/BasisLink';
import Divider from '@UI/divider/Divider';
import DropZone from '@UI/dropZone/DropZone';
import EducationalPointer from '@UI/educationalPointer/EducationalPointer';
import GoalDemarcation from '@UI/goalDemarcation/GoalDemarcation';
import GoalLink from '@UI/goalLink/GoalLink';
import GoalSubItem from '@UI/goalSubItem/GoalSubItem';
import GoalTypeIcon from '@UI/goalTypeIcon/GoalTypeIcon';
import { Checkbox, DistributeButtonList, HoveredPopover } from '@UI/index';
import NonderivedMenuOptions from '@UI/nonderivedMenuOptions/NonderivedMenuOptions';
import ReadOrderViz from '@UI/readOrderViz/ReadOrderViz';
import SuggestionLink from '@UI/sugggestionLink/SuggestionLink';
import TermModal from '@UI/termModal/TermModal';
import { bool, func, object, string } from 'prop-types';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { useDispatch, useSelector } from 'react-redux';

import GrabIcon from '@assets/img/grab_white.svg';
import AttitudeIcon from '@assets/img/icon_attitude.svg';
import DistributedIcon from '@assets/img/icon_distributed.svg';
import { toggleSelectedItem } from '@store/curriculum/curriculumState';
import ModalErrorBoundary from '@UI/errorBoundaries/ModalErrorBoundary';
import { addUnsaveGoalOcurrence, removeUnsaveGoalOcurrence } from '@utils/calendarHelper';

import settings from '@config/settings';
import './goal.scss';

const getGoalClassNames = (goal, isExcluded, canDistribute) => {
  let classname = '';
  if (goal.foundational) classname += ' foundational';
  if (isExcluded) {
    classname += ' goal-excluded';
  } else {
    classname += ' goal-included';
  }
  if (goal.partialInclusion || goal.partialDistribution) classname += ' partial';
  if (canDistribute) {
    classname += ' react-distribute-goal';
  } else {
    classname += ' react-goal';
  }

  return classname;
};

const Goal = ({
  goal,
  isFirstItem,
  subGoal,
  parentKey,
  onShowEditGoalModal,
  onShowNonderivedModal,
}) => {
  const termModalRef = useRef();
  const goalRef = useRef();
  const distributionList = useSelector((state) =>
    selectDistributeVMForGoal(state, { goalHref: goal.$$meta.permalink })
  );
  const isSelectionMode = useSelector((state) => state.curriculum.selectionMode);
  const isReadonlyMode = useSelector(selectIsSelectionReadonlyMode);
  const { allowOptional, allowSubitem } = useSelector((state) => state.curriculum.selectionOptions);
  const isSelected = useSelector((state) => selectIsGoalSelected(state, { key: goal.key }));
  const goalCount = useSelector((state) => selectGoalCount(state, { key: goal.key }));
  const readonly = useSelector(selectCurriculaReadOnly);
  const isDraggable = !readonly && !goal.locked && !isSelectionMode && goal.draggable;
  const isSelectable = useMemo(() => {
    if (!isSelectionMode || isReadonlyMode) return false;
    const allowSubGoals = !subGoal || (subGoal && allowSubitem);
    const allowExcluded = !goal.excluded || (goal.excluded && allowOptional);

    return allowExcluded && allowSubGoals;
  }, [allowSubitem, subGoal, goal.excluded, isSelectionMode, allowOptional, isReadonlyMode]);
  const [hover, setHover] = useState(false);

  const isExcluded = goal.excluded && !hover;
  const [showDistributeWindow, setShowDistributeWindow] = useState(false);
  const isDistributionMode = useSelector(selectIsDistributionMode);
  const isDistributable = useSelector((state) => selectIsCurriculaDistributable(state));
  const emptyOrLowPrioGoals = useSelector((state) => selectEmptyOrLowPrioGoals(state));
  const isEmpty = emptyOrLowPrioGoals.has(goal.$$meta.permalink);
  const goalClassName = getGoalClassNames(goal, isExcluded, isDistributionMode && isDistributable);

  const dispatch = useDispatch();

  const includeGoal = (g) => {
    dispatch(includeItem({ item: g }));
  };

  const onCompleteDistributionOrDelete = (d) => {
    dispatch(completeDistributionOrDeleteItem({ item: d }));
  };

  const onDistributeGoal = (d) => {
    dispatch(distributeGoal({ selectedCurr: d, goalHref: goal.$$meta.permalink }));
  };

  const onSelect = (item) => {
    dispatch(toggleSelectedItem({ item }));
  };

  const onOpenDistributeHandler = () => {
    setShowDistributeWindow(true);
  };

  const onCloseDistributeHandler = () => {
    setShowDistributeWindow(false);
  };

  const compilerOptions = {
    term: {
      compile: true,
      onClick: (key) => {
        const term = goal.terms?.find((t) => t.key === key);
        if (term) {
          termModalRef.current?.open({ term });
        }
      },
    },
    markedText: {
      compile: true,
      modes: settings.hardcodings.markExplanation,
      onHover: (key, text, setter) => {
        const explanation = goal.markExplanations?.find((mark) => mark.key === key);
        if (explanation) setter(explanation.description);
      },
    },
  };

  const onSubItemTermClick = (options) => {
    termModalRef.current?.open(options);
  };

  const onSelectHandler = () => {
    if (!isSelected) {
      addUnsaveGoalOcurrence(goal.$$meta.permalink);
    } else removeUnsaveGoalOcurrence(goal.$$meta.permalink);

    const selectedGoal = { ...goal, selected: isSelected };
    onSelect?.(selectedGoal);
  };

  // This is a workaround to fix an issue in react-dnd that makes the text unselectable even when the element is not draggable
  useEffect(() => {
    if (goalRef.current) {
      if (isDraggable) {
        goalRef.current.setAttribute('draggable', 'true');
      } else {
        goalRef.current.setAttribute('draggable', 'false');
      }
    }
  }, [isDraggable]);

  const [{ isDragging }, dragRef] = useDrag(
    () => ({
      type: 'goal',
      item: { href: goal.$$meta.permalink },
      canDrag: () => isDraggable && !subGoal,
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }),
    [isDraggable, goal.key, subGoal]
  );

  const [{ isHovering, shouldShowDropZone }, dropRef] = useDrop(
    () => ({
      accept: 'goal',
      drop: (item) => {
        dispatch(
          moveItem({
            parent: parentKey,
            sibling: goal.$$meta.permalink,
            item: item.href,
          })
        );
      },
      canDrop: (item) => item.href !== goal.$$meta.permalink && !subGoal,
      collect: (monitor) => ({
        isHovering: monitor.isOver(),
        shouldShowDropZone: monitor.canDrop() || parentKey === '/content/FOUNDATIONALPLACEHOLDER',
      }),
    }),
    [goal.key]
  );

  dragRef(dropRef(goalRef));

  return (
    <div
      ref={goalRef}
      className={`react-goal ${goalClassName} ${goal.hidden ? 'hidden' : ''} ${
        isDragging ? 'dragging' : ''
      }`}
    >
      <a className="goalanchor" id={goal.identifier}></a>

      {isSelectionMode && (
        <div className="selection">
          {isSelectable && (
            <Checkbox checked={isSelected} small onChange={onSelectHandler}></Checkbox>
          )}
          {goalCount > 0 && (
            <HoveredPopover
              content={`${goalCount} x opgenomen in vorderingsplan`}
              positions={['right']}
            >
              <div className="goal-count">{goalCount}</div>
            </HoveredPopover>
          )}
        </div>
      )}

      <NonderivedMenuOptions
        goal={goal}
        isFirstItem={isFirstItem}
        onShowNonderivedModal={onShowNonderivedModal}
        onShowEditGoalModal={onShowEditGoalModal}
        onCompleteDistributionOrDelete={onCompleteDistributionOrDelete}
        onExclude={includeGoal}
      >
        <div
          className="content-container"
          onMouseOver={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          <div className="left-section">
            {isDraggable && (
              <div className="drag-handle">
                <img src={GrabIcon} />
              </div>
            )}
            <div className="goal-identifier">
              <span dangerouslySetInnerHTML={{ __html: goal.identifier.replace(' ', '&nbsp;') }} />
              <GoalTypeIcon type={goal.llinkidGoalType} />
            </div>
            <Divider mandatory={goal.mandatory} />
          </div>

          {goal.attitudinal && (
            <div className="attitude">
              <HoveredPopover content="Attitude">
                <img src={AttitudeIcon} className="attitude-icon" />
              </HoveredPopover>
            </div>
          )}

          <div className="description">
            <Compiler text={goal.description} options={compilerOptions} />
          </div>
          <div className="right-section">
            <SuggestionLink suggestions={goal.edActivityTypes} />
            {goal.educationalPointers?.map((pointer, index) => (
              <EducationalPointer key={index} pointer={pointer} />
            ))}
            <GoalLink links={goal.relatedGoals} />
            <GoalLink links={goal.implementsGoals} descriptionPreface="Gerealiseerd in: <br />" />
            <GoalLink
              links={goal.partiallyImplementsGoals}
              partial={true}
              descriptionPreface="Gedeeltelijk gerealiseerd in: <br />"
            />
            <BasisLink basis={goal.odetGoals} />

            <ReadOrderViz item={goal} />

            {isDistributionMode && isEmpty && (
              <div className="empty-distribution">
                <HoveredPopover content="Dit leerplandoel werd nog niet toegewezen">
                  <svg viewBox="0 0 114 114" width="30" height="30">
                    <g fill="#9a000b">
                      <path d="m37 42.4h-10l-4-40.4h18z" />
                      <ellipse cx="32" cy="54.4" rx="7.7" ry="7.6" />
                    </g>
                  </svg>
                </HoveredPopover>
              </div>
            )}

            {hasGoalDistributions(distributionList) && (
              <div className="distribution-wrap">
                <HoveredPopover content="distributie naar">
                  <img src={DistributedIcon} className="distributed-icon" />
                </HoveredPopover>

                {distributionList.map((list) => (
                  <DistributeButtonList
                    key={list.hash}
                    list={list.items}
                    type={list.type}
                    small={!isDistributionMode}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      </NonderivedMenuOptions>

      {isDistributionMode && (
        <>
          {isDistributable && (
            <div className={`distribute-section`}>
              <div
                className="distribute-button"
                data-testid="distribute-button"
                onClick={onOpenDistributeHandler}
              >
                <i className="glyphicon glyphicon-share"></i>
              </div>
            </div>
          )}

          {showDistributeWindow && (
            <DistributeWindow
              goal={goal}
              onDistribute={onDistributeGoal}
              onClose={onCloseDistributeHandler}
            />
          )}
        </>
      )}

      {goal.highDemarcations?.map((demarcation) => (
        <GoalDemarcation
          key={demarcation.key}
          className={goal.attitudinal ? 'with-attitudinal' : ''}
          demarcation={demarcation}
          mandatory={goal.mandatory}
        />
      ))}

      <div className="sub-items">
        {goal.subItems?.map((subItem) => (
          <GoalSubItem
            key={subItem.key}
            item={subItem}
            onTermClick={onSubItemTermClick}
            onShowEditGoalModal={onShowEditGoalModal}
            onShowNonderivedModal={onShowNonderivedModal}
          />
        ))}
      </div>

      <div className="sub-goals">
        {goal?.goals?.map((g) => (
          <Goal
            key={g.key}
            goal={g}
            subGoal
            onShowNonderivedModal={onShowNonderivedModal}
            onDistribute={onCompleteDistributionOrDelete}
            onSelect={onSelect}
          />
        ))}
      </div>

      <DropZone visible={isHovering && shouldShowDropZone}>
        {parentKey === '/content/FOUNDATIONALPLACEHOLDER' && (
          <span>De doelen onder deze titel zijn alfabetisch gesorteerd</span>
        )}
      </DropZone>

      <Modal
        ref={termModalRef}
        size="md"
        component={<TermModal />}
        errorBoundaryComponent={ModalErrorBoundary}
      />
    </div>
  );
};

Goal.propTypes = {
  goal: object,
  isFirstItem: bool,
  onShowEditGoalModal: func,
  onShowNonderivedModal: func,
  parentKey: string,
  subGoal: bool,
};

export default Goal;
