import './verticalTabs.scss';
import { NavLink } from 'react-router-dom';

const VerticalTabsHeader = ({ items }) => {
  if (!items) return null;

  return (
    <div className="vertical-tabs-header">
      {items.map((item) => (
        <NavLink
          key={item.key}
          className={`tab-header ${item.disabled ? 'disabled' : ''} ${item.tabClass ?? ''}`}
          to={`../leidraad/${item.key}`}
        >
          <div className="tab-header-icon">{item.icon && <img src={item.icon} />}</div>
          <div className="tab-header-title">
            <div className="font-standard">
              <strong>{item.title}</strong>
            </div>
            {item.subtitle && <span className="subTitle">{item.subtitle}</span>}
          </div>
          {item.completed && (
            <div className="tab-header-check">
              <i className="glyphicon glyphicon-ok"></i>
            </div>
          )}
        </NavLink>
      ))}
    </div>
  );
};

export default VerticalTabsHeader;
